import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MatchController from "../../network/MatchController";
import Spring from "@components/Spring";
import styles from "./styles.module.scss";
import ApiService from "../../network/ApiService";
import AuthService from "../../network/AuthService";
import PlayerRow from "@components/Row/PlayerRow";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PregameView from "@pages/MatchDetailContainer/PregameView";
import CompleteEndView from "@pages/MatchDetailContainer/CompleteEndView";

// Helper function to format date strings
const formatDate = (dateString) => {
  console.log("formatDate - dateString:", dateString);
  if (!dateString) return "Invalid Date";
  const date = new Date(dateString);
  console.log("formatDate - date:", date);
  if (isNaN(date.getTime())) {
    console.log("formatDate - date is invalid");
    return "Invalid Date";
  }
  const day = ("0" + date.getUTCDate()).slice(-2);
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const year = date.getUTCFullYear();
  const hours = ("0" + date.getUTCHours()).slice(-2);
  const minutes = ("0" + date.getUTCMinutes()).slice(-2);
  return `${day}.${month}.${year}, ${hours}:${minutes}`;
};

// Component to render team card
const TeamCard = ({ team, isHome }) => (
  <div className={styles.cardColumn}>
    <img
      className={styles.clubLogo}
      src={team.logo || ""}
      alt={team.name || (isHome ? "Home Team" : "Away Team")}
    />
    <div className={styles.teamName}>
      <h5>{team.name || (isHome ? "Home Team" : "Away Team")}</h5>
    </div>
    {isHome && (
      <img
        className={styles.clubDress}
        src={team.dress || ""}
        alt={team.name || "Away Team"}
      />
    )}
  </div>
);

// Component to render trainer card
const TrainerCard = ({ team, isHome }) => (
  <div className={styles.cardColumn}>
    <img
      className={styles.clubLogo}
      src={team.coach?.image_url || "https://via.placeholder.com/150"}
      alt={team.coach?.name || "Not Provided"}
    />
    <div className={styles.teamName}>
      <h5>{team.coach?.name || "Not Provided"}</h5>
    </div>
  </div>
);

const RefDetailContainer = ({ id }) => {
  // State variables
  const [match, setMatch] = useState(null);
  const [team, setTeam] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [players, setPlayers] = useState(null);
  const [isHome, setIsHome] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isModal1Open, setIsModal1Open] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [playerNumber, setPlayerNumber] = useState("");
  const [isDressChangeOpen, setIsDressChangeOpen] = useState(false);
  const [TeamNegativeValue, setTeamNegativeValue] = useState(false);

  const matchController = new MatchController();
  const apiService = new ApiService();
  const authService = new AuthService();
  const navigate = useNavigate();

  // Fetch the dress change status from the backend
  const fetchDressChangeStatus = async () => {
    try {
      const response = await apiService.get(
        "transferSettings/isDressChangeOpen"
      );
      setIsDressChangeOpen(response);
    } catch (error) {
      console.error("Error fetching dress change status:", error);
    }
  };

  // Refetch match data
  const refetchMatch = async () => {
    try {
      const matchResponse = await matchController.getMatchById(id);
      console.log("Match data refetched:", matchResponse);
      setMatch(matchResponse);
    } catch (error) {
      console.error("Error refetching match data:", error);
    }
  };

  // Filter out players that are not eligible or already in the blanket
  const filterEligiblePlayers = (players, blanket) => {
    const blanketPlayerIds = (blanket || []).map((player) => player.id);
    return (players || []).filter(
      (player) =>
        !blanketPlayerIds.includes(player.id) &&
        player.eligibility === "Spielberechtigt"
    );
  };

  // Build blanket players based on home/away
  const myBlanketPlayers = isHome
    ? [
        ...new Map(
          (match?.home_blanket?.players || []).map((player) => [
            player.id,
            player,
          ])
        ).values(),
      ]
    : [
        ...new Map(
          (match?.away_blanket?.players || []).map((player) => [
            player.id,
            player,
          ])
        ).values(),
      ];

  // Available players to add to the blanket
  const availablePlayers = filterEligiblePlayers(players, myBlanketPlayers);

  // Fetch match and team data
  const fetchMatchAndTeamData = async () => {
    try {
      const matchResponse = await matchController.getMatchById(id);
      console.log("Fetched match data:", matchResponse);
      setMatch(matchResponse);
      if (authService.isAuthenticated()) {
        const storedTeamID = authService.getTeamID();
        if (storedTeamID) {
          const teamResponse = await apiService.get(
            `teams/${storedTeamID}/players`
          );

          const team = await apiService.get(`teams/${storedTeamID}`);
          setTeamData(team);

          console.log("Fetched team data:", teamResponse);
          setTeam(teamResponse);
          setPlayers(teamResponse.players);
          const isHomeTeam = matchResponse.home_team.id === teamResponse.id;
          setIsHome(isHomeTeam);
          setActiveTab(isHomeTeam ? "home" : "away");
          // Check for negative balance
          console.log("BALANCE:", team.balance);
          setTeamNegativeValue(team.balance < 0);
        } else {
          console.error("No teamID found in cookies.");
        }
      }
    } catch (error) {
      console.error("Error fetching match or team data:", error);
    }
  };

  // Initial data fetching on mount or id change
  useEffect(() => {
    fetchMatchAndTeamData();
    fetchDressChangeStatus();
  }, [id]);

  // Update player number when a new player is selected
  useEffect(() => {
    if (selectedPlayer) {
      setPlayerNumber(selectedPlayer.number);
      console.log("Selected player:", selectedPlayer);
    }
  }, [selectedPlayer]);

  // Handlers for opening modals
  const handleItem1Click = (player) => {
    console.log("Player clicked:", player);
    setSelectedPlayer(player);
    setIsModal1Open(true);
  };

  const handleItem2Click = (player) => {
    console.log("Player clicked:", player);
    setSelectedPlayer(player);
    setIsModal2Open(true);
  };

  // Handlers for closing modals
  const handleClose1Modal = () => {
    setIsModal1Open(false);
    setSelectedPlayer(null);
  };

  const handleClose2Modal = () => {
    setIsModal2Open(false);
    setSelectedPlayer(null);
  };

  // Handler for adding a player to the blanket
  const handleAddPlayerToBlanket = async () => {
    try {
      if (playerNumber !== selectedPlayer.number) {
        selectedPlayer.number = playerNumber;
        console.log("Updated player number:", playerNumber);
      }

      const isPlayerAlreadyInBlanket = myBlanketPlayers.some(
        (player) => player.id === selectedPlayer.id
      );
      console.log("Is player already in blanket?", isPlayerAlreadyInBlanket);

      const currentTeamId = authService.getTeamID();
      const playerNumberInt = parseInt(playerNumber, 10);

      if (team.id === currentTeamId) {
        if (isHome) {
          if (!isPlayerAlreadyInBlanket) {
            console.log("Adding player to home blanket...");
            await matchController.addPlayerToHomeBlanket(
              match.id,
              selectedPlayer.id,
              playerNumberInt,
              team.coach
            );
          } else {
            console.log("Player already in home blanket");
          }
        } else {
          if (!isPlayerAlreadyInBlanket) {
            console.log("Adding player to away blanket...");
            await matchController.addPlayerToAwayBlanket(
              match.id,
              selectedPlayer.id,
              playerNumberInt,
              team.coach
            );
          } else {
            console.log("Player already in away blanket");
          }
        }
      }

      await refetchMatch();
      handleClose1Modal();
    } catch (error) {
      console.error("Error adding player to blanket:", error);
    }
  };

  // Handler for removing a player from the blanket
  const handleRemovePlayerFromBlanket = async () => {
    try {
      if (!selectedPlayer || !selectedPlayer.id) {
        console.error("No player selected for removal.");
        return;
      }

      if (isHome) {
        console.log("Removing player from home blanket...");
        await matchController.removePlayerFromHomeBlanket(
          match.id,
          selectedPlayer.id
        );
      } else {
        console.log("Removing player from away blanket...");
        await matchController.removePlayerFromAwayBlanket(
          match.id,
          selectedPlayer.id
        );
      }

      await refetchMatch();
      handleClose2Modal();
    } catch (error) {
      console.error("Error removing player from blanket:", error);
    }
  };

  // Show loading indicator until match and team data are available
  if (!match || !team) return <div>Loading...</div>;

  // Extract blanket teams (or empty objects if not available)
  const homeTeam = match.home_blanket || {};
  const awayTeam = match.away_blanket || {};

  // Function to render the pregame view based on match status
  const renderView = () => {
    switch (match?.status) {
      case "pending":
        return (
          <PregameView
            match={match}
            onPlayerClick={(player) => handleItem2Click(player, "player")}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isHome={isHome}
          />
        );
      default:
        return null;
    }
  };

  // ********************* Render Sections as Constants *********************

  const OKText = () => {
    return (
      <p className={styles.disclaimer}>
        Sie können mit dem angezeigten Knopf ihre Trikotgarnitur ändern.
        Wöchentlich Montag bis Donnerstag, um 12:00 vor Spielbeginn.
        <br />
        Als Auswärtsteam - stellen Sie bitte sicher, dass Sie diese{" "}
        <span
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            color: "orange",
          }}
        >
          <a
            href="https://oekfb.eu/ligaordnung#section7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ligaordnung § 7 - Trikots
          </a>
        </span>{" "}
        beachten, wenn es um die Wahl Ihrer Trikotgarnitur geht.
      </p>
    );
  };

  const ErrText = () => {
    const handleExtendDeadline = async () => {
      if (teamData?.id) {
        try {
          await matchController.overdraft(teamData.id); // Wait until the request completes
          window.location.reload(); // Reload only after the API call succeeds
        } catch (error) {
          console.error("Error extending deadline:", error);
        }
      } else {
        console.error("Error: teamData.id is undefined");
      }
    };

    return (
      <p className={styles.disclaimer}>
        Ihr aktuelles Saldo beträgt
        <span
          style={{
            color: "orange",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontWeight: "bold",
          }}
        >
          {teamData.balance}€
        </span>
        , was einen negativen Betrag darstellt. Um das Blankett auszufüllen,
        darf Ihr Saldo nicht negativ sein. Bitte begleichen Sie den offenen
        Betrag bis spätestens Mittwoch, damit die Verrechnung rechtzeitig im
        Admin-System bearbeitet werden kann und es zu keinen Verzögerungen
        kommt.
        <br></br>
        <br></br>
        Bitte beachten Sie, dass wir nicht garantieren können, dass Zahlungen,
        die nach Mittwoch eingehen, rechtzeitig verarbeitet werden.
        <br></br>
        <br></br>
        {teamData?.overdraft === false && (
          <>
            Falls Sie mehr Zeit benötigen, haben Sie die Möglichkeit,{" "}
            <span
              onClick={handleExtendDeadline}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: "bold",
                color: "orange",
              }}
            >
              Ihre Zahlungsfrist gegen eine Gebühr von 50 € auf Montag zu
              verschieben.
            </span>
            <br></br>
          </>
        )}
        Vielen Dank für Ihr Verständnis.
      </p>
    );
  };

  // Render the team section (teams, location, date, and dress change button)
  const teamSection = (
    <div className={styles.card}>
      <div className={styles.grid}>
        <TeamCard team={homeTeam} isHome={true} />
        <div className={styles.middleCol}>
          <p className={styles.location}>{match.details.location}</p>
          <p className={styles.date}>{formatDate(match.details.date)}</p>
          {/* Toggle Dress Button */}
          {isDressChangeOpen && isHome && (
            <button
              className={styles.dressChangeButton}
              onClick={async () => {
                try {
                  await matchController.toggleDress(
                    match.id,
                    isHome ? "home" : "away"
                  );
                  await refetchMatch();
                } catch (error) {
                  console.error("Error toggling dress:", error);
                }
              }}
            >
              Trikot ändern
            </button>
          )}
        </div>
        <TeamCard team={awayTeam} isHome={false} />
      </div>
      {TeamNegativeValue ? <ErrText /> : <OKText />}
    </div>
  );

  // Render the trainer section with a heading and the trainer card
  const trainerSection = (
    <div className={styles.card}>
      <h4 style={{ marginBottom: "10px" }}>Trainer</h4>
      <div>
        {isHome ? (
          <TrainerCard team={homeTeam} isHome={true} />
        ) : (
          <TrainerCard team={awayTeam} isHome={false} />
        )}
      </div>
    </div>
  );

  // Render the match view section (e.g., pregame view)
  const matchViewSection = <div className={styles.card}>{renderView()}</div>;

  // Render the players section listing all available players
  const playersSection = (
    <div className={styles.card}>
      <div className="d-flex flex-column g-1 justify-content-center p-2">
        {availablePlayers.map((player) => (
          <PlayerRow
            key={player.id}
            player={player}
            onClick={() => handleItem1Click(player)}
          />
        ))}
      </div>
    </div>
  );

  // Render modal for adding a player to the blanket
  const addPlayerModal = (
    <Modal open={isModal1Open} onClose={handleClose1Modal}>
      <Box className={styles.modalContent}>
        {selectedPlayer ? (
          <div className={styles.playerModalContainer}>
            <img
              src={selectedPlayer.image || "https://via.placeholder.com/150"}
              alt={selectedPlayer.name}
              style={{ maxWidth: "400px" }}
            />
            <h3 className={styles.playerModalContainerName}>
              {selectedPlayer.name}
            </h3>
            <label>
              Spieler Nummer Überschreiben:
              <input
                type="number"
                value={playerNumber}
                onChange={(e) => setPlayerNumber(e.target.value)}
                className={styles.playerNumberInput}
              />
            </label>
            <button
              onClick={handleAddPlayerToBlanket}
              className={styles.btnOrange}
              disabled={myBlanketPlayers.length >= 12}
            >
              Spieler zum Blankett zuweisen
            </button>
            {myBlanketPlayers.length >= 12 && (
              <p className={styles.warningText}>
                Maximal 12 Spieler im Blanket erlaubt.
              </p>
            )}
          </div>
        ) : (
          <p>No data available</p>
        )}
      </Box>
    </Modal>
  );

  // Render modal for removing a player from the blanket
  const removePlayerModal = (
    <Modal open={isModal2Open} onClose={handleClose2Modal}>
      <Box className={styles.modalContent}>
        {selectedPlayer ? (
          <div className={styles.playerModalContainer}>
            <img
              src={selectedPlayer.image || "https://via.placeholder.com/150"}
              alt={selectedPlayer.name}
              style={{ maxWidth: "400px" }}
            />
            <h3 className={styles.playerModalContainerName}>
              {selectedPlayer.name}
            </h3>
            {(activeTab === "home" &&
              isHome &&
              match.home_blanket?.players.some(
                (player) => player.id === selectedPlayer.id
              )) ||
            (activeTab === "away" &&
              !isHome &&
              match.away_blanket?.players.some(
                (player) => player.id === selectedPlayer.id
              )) ? (
              <button
                onClick={handleRemovePlayerFromBlanket}
                className={styles.btnOrange}
              >
                Spieler vom {isHome ? "Home" : "Away"} Blankett entfernen
              </button>
            ) : null}
          </div>
        ) : (
          <p>No data available</p>
        )}
      </Box>
    </Modal>
  );

  // ************************* Main Render *************************
  return (
    <Spring>
      {teamSection}
      {trainerSection}
      {matchViewSection}
      {TeamNegativeValue ? null : playersSection}
      {teamData.overdraft && new Date(teamData.overdraft_date) > new Date()
        ? playersSection
        : null}
      {addPlayerModal}
      {removePlayerModal}
    </Spring>
  );
};

export default RefDetailContainer;
