import React from "react";
import styles from "./styles.module.scss";
import Spring from "@components/Spring";
import Score from "@ui/Score";
import PropTypes from "prop-types";

const MatchCard = ({ match, index, variant = "basic" }) => {
  const homeTeam = match?.home_blanket || {};
  const awayTeam = match?.away_blanket || {};

  const formatDate = (dateString) => {
    if (!dateString) return "Nicht Zugeornet";
    const datePart = dateString.split("T")[0];
    const timePart = dateString.split("T")[1].replace("Z", "");

    const [year, month, day] = datePart.split("-");
    const [hour, minute] = timePart.split(":");

    return `${day}.${month}.${year} ${hour}:${minute}`;
  };

  const goToBlankett = () => {
    if (match.id) {
      window.location.href = `/kader/matches/${match.id}`;
    } else {
      console.error("Unable to confirm, player or team is missing.");
    }
  };

  const matchStatus = (matchData) => {
    if (!matchData) return;
    const { status } = matchData;
    switch (status) {
      case "pending":
        return "Spielvorschau";
      case "cancelled":
        return "Spiel Abgesagt";
      case "completed":
        return "Spiel Abgeschlossen";
      default:
        return "Spiel Nicht Bearbeitbar";
    }
  };

  const matchButtonTxt = (matchData) => {
    if (!matchData) return;
    const { status } = matchData;
    if (status === "pending") {
      return "Blankett Ausfüllen";
    }
    return "Blankett Gesperrt";
  };

  return (
    <Spring className={`${styles.container}`} type="slideUp" index={index}>
      <div
        className={"card-padded d-flex flex-column g-20"}
        style={{
          paddingBottom: variant !== "extended" ? "var(--card-padding)" : 10,
          cursor: "pointer",
        }}
      >
        <div className="d-flex align-items-center justify-content-between p-relative">
          <img
            className={styles.clublogo}
            src={homeTeam.logo || ""}
            alt={homeTeam.name || "Home Team"}
          />
          <Score
            team1={match.score.home || 0}
            team2={match.score.away || 0}
            variant="alt"
          />
          <img
            className={styles.clublogo}
            src={awayTeam.logo || ""}
            alt={awayTeam.name || "Away Team"}
          />
        </div>

        <div className="d-flex justify-content-between g-30">
          <div style={{ minWidth: 0, maxWidth: "100px" }}>
            <h5>{homeTeam.name || "Home Team"}</h5>
          </div>

          <div>
            <h4
              style={{ color: "orange", textAlign: "center" }}
              className="text-12 text-overflow"
            >
              Status:
            </h4>
            <h2
              style={{ textAlign: "center", maxWidth: "150px", height: "auto" }}
              className="text-12 text-overflow"
            >
              {matchStatus(match) || "Nicht Zugeornet"}
            </h2>
          </div>

          <div
            className="text-right"
            style={{ minWidth: 0, maxWidth: "100px", textAlign: "right" }}
          >
            <h5>{awayTeam.name || "Away Team"}</h5>
          </div>
        </div>

        <div className="d-flex justify-content-between g-30 row">
          <img
            className={styles.dress}
            src={match.home_blanket.dress || ""}
            alt={homeTeam.name || "Home Team"}
          />

          {match.details.date && (
            <div className="d-flex flex-column justify-content-center g-5">
              <h5
                style={{ color: "orange", textAlign: "center" }}
                className="text-12 text-overflow"
              >
                Sportplatz:
              </h5>

              <h2
                style={{
                  textAlign: "center",
                  maxWidth: "150px",
                  height: "auto",
                  color: "white",
                }}
                className="text-10"
              >
                {match.details.location || "Nicht Zugeornet"},{" "}
                {formatDate(match.details.date) || "Nicht Zugeornet"}
              </h2>
            </div>
          )}

          <div style={{ minWidthwidth: "80px" }}></div>
        </div>

        {match.status === "pending" && (
          <button className={styles.btnOrange} onClick={goToBlankett}>
            {matchButtonTxt(match)}
          </button>
        )}
      </div>

      {variant === "extended" && (
        <div className="border-top">
          {/* Add additional extended view details if required */}
        </div>
      )}
    </Spring>
  );
};

MatchCard.propTypes = {
  match: PropTypes.object.isRequired,
  index: PropTypes.number,
  variant: PropTypes.oneOf(["basic", "extended"]),
};

export default MatchCard;
